import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import {Row, Col} from 'react-bootstrap';
import CTAButton from '../utils/CTAButton';
import { Link } from 'gatsby';




const AdBlock = (props) => {
  const Card = styled.div`
    display: flex;
    align-items: center;
    min-height: 900px;
    background-image: 
      linear-gradient(
        rgba(227, 79, 48, 0.75), 
        rgba(227, 79, 48, 0.75)
      ),
      url(${props.data.backgroundImage.fluid.src});
    @media (max-width: 475px) {
      min-height: 1050px;
      padding-bottom: 60px;
    }
    @media (max-width: 320px) {
      min-height: 950px;
      padding-bottom: 0px;
    }
  `
  const ExternalWrapper = styled(Row)`
    max-width: 48vw;
    width: 48vw;
    margin: 0 auto !important;
    @media (max-width: 475px) {
      max-width: 100vw;
      width: 100vw;
      margin: 0 auto !important;
      flex-direction: column-reverse;
    }
    @media (max-width: 768px) {
      height: 820px;
      width: 90vw;
      max-width: 90vw;
    }
  `
  const InternalWrapper = styled(Col)`
    @media (max-width: 768px) {
      max-width: unset;
      a {
        height: 4.8125rem !important;
        line-height: 2.7;
      }
    }
    max-width: 21vw;
    margin: auto auto !important;
    &.center-internal-col {
      padding: 0;
      display: flex;
      flex-flow: row wrap;
      a {
        margin-left: unset;
      }
    }
    @media (max-width: 375px) {
    &.center-internal-col {
      width: 96.5%;
      a {
        margin-left: unset;
        max-width: 100vw;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  `
  const TextBlock = styled.p`
    color: ${props => props.theme.colors.white};
    font-size: 1.25em;
    margin-top: 1.6875rem;
    margin-bottom: 2.5rem;
  `
  const StyledImage = styled(Img)``

  const ImageWrapper = styled.div`
    cursor: pointer;
    padding: 12px;
    border: 3px solid ${props => props.theme.colors.white} !important;
    min-width: 362.34px;
    @media (max-width: 768px) {
      margin: 0 -10px;
      min-width: 282px;
    }
  `
  const ctaText = props.data.ctaText.internal.content
  const ctaButtonText = props.data.ctaButtonText
  return (
    <Card>
      <ExternalWrapper>
        <InternalWrapper className='center-internal-col'>
          <TextBlock>{ctaText}</TextBlock>
          <CTAButton variant='white-white' href='/ads'>{ctaButtonText}</CTAButton>
        </InternalWrapper>
        <InternalWrapper>
          <Link to="/ads">
            <ImageWrapper>
              <StyledImage fluid={props.data.adImage.fluid} backgroundColor={'none'} />
            </ImageWrapper>
          </Link>
        </InternalWrapper>
      </ExternalWrapper>
    </Card>
  )
}
export default AdBlock
