import Axios from 'axios'
import React from 'react'
import styled from 'styled-components'
import { Button, Form, Row } from 'react-bootstrap';

const Wrapper = styled(Form)`
  display: block;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto 8.1rem;
  max-width: 380px;
`
const EmailSubmit = styled(Button)`
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    height: 100%;
    color: ${props => props.theme.colors.base} !important;
    width: 217px;
    font-size: 1.1875rem !important;
    text-align: center;
    background: ${props => props.theme.colors.highlight} !important;
    border-radius: 0px;
    border-color: ${props => props.theme.colors.base} !important;
    border: 3px solid ${props => props.theme.colors.base} !important;
    &:hover {
      border-radius: 0px;
      color: ${props => props.theme.colors.white} !important;
      background: ${props => props.theme.colors.base} !important;
    }
    padding: 10px 0px !important;
    margin: 0 auto 0;
    @media (max-width: 768px) {
      width: 100%;
      height: 4.8125rem !important;
      line-height: 2.5;
    }
`

const EmailInput = styled(Form.Control)`
    color: ${props => props.theme.colors.tertiary};
    background-color: ${props => props.theme.colors.white};
    border: 2px ${props => props.theme.colors.tertiary} solid;
    border-radius: unset !important;
    outline: none;
    width: 380px;
    padding: 8px;
    font-size: 18px;
    margin-right: 30px;
    margin: 0 auto 1em;
`
const AltEmailInput = styled.input``
const AltEmailSubmit = styled.input``
const AltWrapper = styled.div`
  position: absolute; 
  left: -5000px;
`
  
export default class MailChimpForm extends React.Component {
  render() {
    return (
      <Wrapper action="https://market.us16.list-manage.com/subscribe/post?u=f02210666a61a3e1f8790c678&amp;id=96c25b9fc2" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
        <Row>
          <EmailInput onChange={this.handleChange} id="mce-EMAIL" type="email" defaultValue="" name="EMAIL" size="lg" placeholder="Sign up here to join!"/>
        </Row>
        <Row>
          <EmailSubmit onClick={this.handleSubmit} as="input" type="submit" value="Submit"/>
        </Row>
        {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
        <AltWrapper aria-hidden="true">
          <AltEmailInput type="text" name="b_f02210666a61a3e1f8790c678_96c25b9fc2" tabIndex="-1" defaultValue=""/>
          <AltEmailSubmit type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/>
        </AltWrapper>
      </Wrapper>
    )
  }
}