import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import {Row, Col} from 'react-bootstrap';


const MainCard = styled(Row)`
  width: 80%;
  margin: 0 auto 11rem auto !important;
  div.col-sm-6:nth-child(1) {
    padding-right: 5.344375rem;
  }
  div:nth-child(1) > div.gatsby-image-wrapper {
    float: right;
  }
  @media (max-width: 475px) {
    width: 81vw;
    margin: 0 auto 4rem auto !important;
    .main-image-block {
      display: none;
    }
  }
  @media (max-width: 768px) {
    width: 81vw;
    margin: 0 auto 4rem auto !important;
    div.col-sm-6:nth-child(1) {
      padding-right: 0rem;
    }
  }
`

const Image = styled(Img)`
  min-width: 300px;
  min-height: 300px;
  max-height: 416px;
  max-width: 368px;
  margin: auto;
  @media (max-width: 768px) {
    position: initial;
  }
`

const Headline = styled.h4`
  padding-top: 80px;
  color: ${props => props.theme.colors.base}; 
  font-size: 33px; 
  @media (max-width: 475px) {
    font-size: 1.6875rem; 
    padding-top: 0px;
  }
`
const Excerpt = styled.div`
  max-width: 456px;
  p { 
    height: 100%;
    display: inline-block;
    line-height: 1.6;
    vertical-align: middle;
  }
  @media (max-width: 475px) {
    p{
      font-size: 1.125rem;
    }
  }
`
const TextContainer = styled(Col)`
  @media (max-width: 667px) {
    padding-left: 0px !important;
  }
  @media (min-width: 768px) {
    padding-left: 2.1rem !important;
  }
`

const MainCardBlock = (props) => {
  return (
      <MainCard>
        <Col sm={6} className={'main-image-block'}>
          <Image fluid={props.mainCard.cardImage.fluid} backgroundColor={'#eeeeee'} />
        </Col>
        <TextContainer sm={6}>
          <Headline>{props.mainCard.headline}</Headline>
          <Excerpt dangerouslySetInnerHTML={{
                __html: props.mainCard.textBlock.childMarkdownRemark.html,
              }}
          mainCard/>
        </TextContainer>
      </MainCard>
    )
}

export default MainCardBlock
