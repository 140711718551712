import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import Img from 'gatsby-image';
import {Row, Col} from 'react-bootstrap';
import CTAButton from '../utils/CTAButton';




const LocationBlock = (props) => {
  const Card = styled.div`
    display: flex;
    align-items: center;
    min-height: 600px;
    background-image: 
      linear-gradient(
        rgba(227, 79, 48, 0.75), 
        rgba(227, 79, 48, 0.75)
      ),
      url(${props.data.backgroundImage.fluid.src});
    @media (max-width: 475px) {
      min-height: 430px;
    }
  `
  const ExternalWrapper = styled(Row)`
    margin: 0 auto !important;

  `
  const InternalWrapper = styled(Col)`
    margin: auto auto !important;
  `
  const ctaButtonText = props.data.ctaButtonText
  return (
      <Card>
        <ExternalWrapper>
          <InternalWrapper>
            <CTAButton variant="white-white" size="large" href="/contact">{ctaButtonText}</CTAButton>
          </InternalWrapper>
        </ExternalWrapper>
      </Card>
    )
}

export default LocationBlock
