import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Newsletter from '../components/Forms/Newsletter'
import MainCardBlock from '../components/Home/MainCardBlock'
import AdBlock from '../components/Home/AdBlock'
import EnrollBlock from '../components/Home/EnrollBlock'
import LocationBlock from '../components/Home/LocationBlock'
import HeroMasthead from '../components/Home/HeroMasthead'
import Container from '../components/Container'
import SEO from '../components/SEO'
import ItemRequestFormOnHome from '../components/Home/ItemRequestFormOnHome'

const Index = ({ data }) => {
  const heroImages = data.allContentfulHomeHeroMasthead.edges
  const mainCard = data.allContentfulHomeMainCardBlock.edges[0].node
  const adBlockData = data.allContentfulHomePageAdBlock.edges[0].node
  const storeBlockData = data.allContentfulHomeStoreBlock.edges[0].node
  const enrollBlockData = data.allContentfulHomeEnrollBlock.edges[0].node
  const stores = data.allContentfulStore.edges
  return (
    <Layout>
      <SEO />
      <Container>
        <HeroMasthead heroImages={heroImages}/>
        <MainCardBlock mainCard={mainCard}/>
        <AdBlock data={adBlockData}/>
        <Newsletter/>
        <LocationBlock data={storeBlockData}/>
        <EnrollBlock data={enrollBlockData}/>
      </Container>
      <ItemRequestFormOnHome stores={stores}/>
    </Layout>
  )
}

export const query = graphql`
query{
  allContentfulHomeHeroMasthead (
      sort: { fields: [createdAt], order: DESC }
  ) {
    edges {
      node {
        heroMastheadImage {
          fluid(maxWidth: 1800, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        headline
        tagline
      }
    }
  }
  allContentfulHomeMainCardBlock (
      sort: { fields: [createdAt], order: DESC }
      limit: 1
  ) {
    edges {
      node {
        headline
        cardImage {
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        textBlock {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
  allContentfulHomePageAdBlock(
    sort: { fields: [createdAt], order: DESC }
    limit: 1
  ) {
    edges {
      node {
        ctaText{
          internal {
            content
          }
        }
        ctaButtonText
        backgroundImage {
          fluid(maxWidth: 1920){
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        adImage {
            fluid(maxWidth: 400){
              ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
  allContentfulHomeStoreBlock(
    sort: { fields: [createdAt], order: DESC }
    limit: 1
  ){
    edges {
      node {
        ctaButtonText
        backgroundImage {
          fluid(maxWidth: 1920){
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
  allContentfulHomeEnrollBlock(
    sort: { fields: [createdAt], order: DESC }
    limit: 1
  ) {
    edges {
      node {
        ctaButtonText
        ctaText {
          internal {
            content
          }
        }
        image {
            fluid(maxWidth: 400){
              ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
  allContentfulStore(sort: {fields: [reedsBrandedStore], order: [DESC]}){
    edges {
      node {
        id
        storeName
        localName
        loyaltyLaneStoreId
      }
    }
  }
}
`

export default Index
