import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap';
import ControlledCarousel from '../images/Carousel';

const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto 10rem auto !important;
    @media (max-width: 475px) {
      margin: 0 auto 2.3125rem auto !important;
    }
    @media (max-width: 768px) {
      margin: 0 auto 2.3125rem auto !important;
    }
`
const HR = styled.hr`
  display: block;
  background-color: ${props => props.theme.colors.base};
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: 475px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`
const TopSpacer = styled.div`
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: 475px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`

const HeroMasthead = (props) => {
  return (
      <Wrapper>
          <TopSpacer/>
            <ControlledCarousel images={props.heroImages} backgroundColor={'#eeeeee'} />
          <HR/>
      </Wrapper>
    )
}

export default HeroMasthead
