


import React from 'react'
import styled from 'styled-components'
import {Row, Col, Container} from 'react-bootstrap';
import MailChimpForm from './MailChimpForm'

const Wrapper = styled(Container)`
  display: block;
  margin-top: 9.5rem;
  margin-bottom: 9.5rem;
  width: 40% !important;
  @media (max-width: 475px) {
    width: 90% !important;
  }
  @media (max-width: 768px) {
    margin-top: 4.8125rem;
    margin-bottom: 4.8125rem;
    width: 90% !important;
  }
`

const Header = styled.h2`
  text-align: center;
  color: ${props => props.theme.colors.base};
`
const Text = styled.p`
  text-align: center;
  font-size: 14px;
  @media (max-width: 475px) {
    font-size: 1.1em;
  }
  @media (max-width: 768px) {
    font-size: 1.1em;
  }
`


const Newsletter = () => (
    <Wrapper>
      <Header>Want to be the first to know about our
      special sales, discounts, and rewards?</Header>
      <Text>SIGN UP FOR OCCASIONAL EMAILS AND OUR WEEKLY AD</Text>
      <MailChimpForm/>
    </Wrapper>
  )
  
export default Newsletter