import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Img from 'gatsby-image'

const StyledCarousel = styled(Carousel)`

:root {
  --hero-height: 510.05px;
}

li.dot {
  height: 15px !important;
  width: 15px !important;
  background: none !important;
  opacity: 1 !important;
  border: 1px solid ${props => props.theme.colors.white} !important;

  &:hover {
    background: ${props => props.theme.colors.white} !important;
    border: 1px solid ${props => props.theme.colors.white} !important;
  }
}

li.selected {
  background: ${props => props.theme.colors.white} !important;
  border: 1px solid ${props => props.theme.colors.white} !important;
}
@media (max-width: 475px) {
  .gatsby-image-wrapper > div {
    padding-bottom: 80.2459% !important;
  }
}
@media (min-width: 736px) {
  .gatsby-image-wrapper > div {
    padding-bottom: 56.2459% !important;
  }
}
@media (min-width: 1024px) {
  .gatsby-image-wrapper > div {
    padding-bottom: 32% !important;
  }
}

@media (min-width: 1024px) {
  .gatsby-image-wrapper > div {
    padding-bottom: 32% !important;
  }
}
@media (max-width: 1024px) {
  .control-dots {
    display: none;
  }
}
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 100px;
  width: 50%;
  left: 25%;
  @media (max-width: 768px) {
    left: 0;
  }
`
const ContentWrapper = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: 2.3em;
`
const Image = styled(Img)`
  min-width: 100%;
  min-height: 335px;
  max-height: --hero-height;
  margin: auto;
`
const HeroHeadline = styled.h1`
  display: inline-block;
  font-size: 3.75rem;
  color: ${props => props.theme.colors.secondaryAlt};
  padding: 1rem 14.8125rem 0.875rem 3.6875rem;
  background-color: rgba(218, 220, 220, 0.8);
  margin: 0;
  text-align: left;
  float: left;
  width: max-content;
  @media (max-width: 475px) {
    padding: .75rem 14.8125rem 0.5rem 1.25rem;
    font-size: 1.5rem;
  }
  @media (min-width: 667px) {
    font-size: 2.5rem;
  }
`
const HeroTagline = styled.h4`
  display: inline-block;
  font-size: 1.8125rem;
  color: ${props => props.theme.colors.reedsBeige};
  padding: 1rem 1rem 0.875rem 3.6875rem;
  background-color: ${props => props.theme.colors.base};
  margin: 0;
  text-align: left;
  float: left;
  @media (max-width: 475px) {
    padding: .75rem 1rem 0.5rem 1.25rem;
    font-size: .75rem;
  }
  @media (min-width: 667px) {
    font-size: 1.2rem;
  }
`

export default class ControlledCarousel extends Component {
  render() {
      return (
        // autoPlay
          <StyledCarousel infiniteLoop autoPlay swipeable={true} showIndicators={true} showThumbs={false} showStatus={false}>
              {this.props.images.map(({ node: image }, i) => (
                            <div key={i}>
                                <Image fluid={image.heroMastheadImage.fluid} aspectRatio={16/9} />
                                <Wrapper>
                                  <ContentWrapper>
                                      {image.headline ? <HeroHeadline>{image.headline}</HeroHeadline> : null }
                                      {image.tagline ? <HeroTagline>{image.tagline}</HeroTagline> : null }
                                  </ContentWrapper>
                                </Wrapper>
                            </div>
              ))}
          </StyledCarousel>
      );
  }
};
