import React from 'react'
import styled from 'styled-components'
import {Col, Row} from 'react-bootstrap';
import ItemRequestForm from '../Forms/ItemRequestForm'


const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto !important;
    padding: 5rem 0 5rem 0;
    background-color: ${props => props.theme.colors.reedsBeige};
`
const ContentWrapper = styled(Row)`
    width: 50%;
    margin: 0 auto 0 auto !important;
    @media (max-width: 475px) {
      width: 90%;
      margin: 0 auto 0 auto !important;
    }
    @media (max-width: 768px) {
      width: 90%;
      margin: 0 auto 0 auto !important;
    }
`
const Header = styled.div`
  color: ${props => props.theme.colors.base};
  margin-bottom: 2.5rem;
  @media (max-width: 475px) {
    padding-left: 15px;
  }
`
const Text = styled.p`

  @media (max-width: 475px) {
    font-size: 1.2em; 
    padding-top: 0px;
  }
  @media (max-width: 768px) {
    font-size: 1.2em; 
    padding-top: 0px;
  }

`

const ContactContainer = styled(Col)``
const TextContainer = styled(Col)``

const HeroMasthead = (props) => {
  return (
      <Wrapper>
          <ContentWrapper>
              <Header>
                <h2>Looking for something you can't find?</h2>
                <h2>Request an item here.</h2>
              </Header>
            <TextContainer sm={5}>
                <Text>We love to hear more about what our customers are looking for. If there's something you'd like to request, we'd like to see if we can get it for you. Fill out the submission form, and stay tuned!</Text>
            </TextContainer>
            <ContactContainer sm={{span: 6, offset: 1}}>
                <ItemRequestForm stores={props.stores}/>
            </ContactContainer>
          </ContentWrapper>
      </Wrapper>
    )
}

export default HeroMasthead
