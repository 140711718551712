import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import {Row, Col} from 'react-bootstrap';
import CTAButton from '../utils/CTAButton';




const EnrollBlock = (props) => {
  const Card = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 475px) {
      height: 40vh;
      margin-top: 4.8125rem;
      margin-bottom: 4.8125rem;
    }
  `
  const ExternalWrapper = styled(Row)`
    width: 71vw;
    height: 621px;
    margin: 10.625em auto !important;
    border: 3px solid ${props => props.theme.colors.base} !important;
    display: flex;
    align-items: center;
    flex-flow: row;
    @media (max-width: 768px) {
      height: unset;
      width: 90vw;
      .offset-md-3 {
        margin-left: 20%;
      }
      border: none !important;
    }
  `
  const InternalWrapper = styled(Col)`
    @media (max-width: 475px) {
      display: flex;
      flex-flow: row wrap;
      a {
        width: 80% !important
      }
    }
    @media (min-width: 475px) {
      max-width: 21vw;
    }
    @media (max-width: 475px) {
      &.styled-img {
        display: none;
      }
    }
  `
  const TextBlock = styled.div`
    color: ${props => props.theme.colors.base};
    font-size: 1.25em;
    margin-bottom: 2.5rem;
    @media (max-width: 475px) {
      width: 80%;
      margin: 0 auto 1.25rem auto;
    }
    h2:first-child {
      margin-bottom: .5rem;
    }
    h2 {
      margin-bottom: 0;
      font-size: 1.25em;
    }

  `
  const StyledImage = styled(Img)`
    height: 22.1875em;
    width: 12.3125em;
    float: right;
  `
  let ctaText
  if (props.data.ctaText.internal.content.includes('\n') ) {
    const data = props.data.ctaText.internal.content.split('\n')
    const lines = data.map((line, index) => (
      <h2 key={index} className={"bold"}>{line}</h2>
    ))
    ctaText = <TextBlock key={'enroll-block-tagline-multi'}>{lines}</TextBlock>
  } else {
    ctaText = <TextBlock key={'enroll-block-tagline-single'}><h2 key={'enroll-block-tagline-single-line'} className={"bold"}>{props.data.ctaText.internal.content}</h2></TextBlock>
  }
  // const ctaButtonText = props.data.ctaButtonText
  // return (
  //     <Card>
  //       <ExternalWrapper>
  //         <InternalWrapper sm={12} md={{span: 3, offset: 3}}>
  //           <StyledImage fluid={props.data.image.fluid} backgroundColor={'none'} />
  //         </InternalWrapper>
  //         <InternalWrapper sm={12} md={6}>
  //           {ctaText}
  //           <CTAButton variant='solid-white' href='/rewards'>{ctaButtonText}</CTAButton>
  //         </InternalWrapper>
  //       </ExternalWrapper>
  //     </Card>
  //   )
    return (
      <Card>
        <ExternalWrapper>
          <InternalWrapper sm={12} md={{span: 3, offset: 3}} className={'styled-img'}>
            <StyledImage fluid={props.data.image.fluid} backgroundColor={'none'} />
          </InternalWrapper>
          <InternalWrapper sm={12} md={6}>
            {ctaText}
            <CTAButton variant="solid-white" href="/coupons#signup">Enroll Now</CTAButton>
          </InternalWrapper>
        </ExternalWrapper>
      </Card>
    )
}

export default EnrollBlock
